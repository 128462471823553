$black: #000000;
$white: #ffffff;
$brown: #1e1b11; //#36311f;
$darkbrown: #13110c;
$lightsand: #ddc6bf; //rgba(221, 198, 191, 1)
$darksand: #9b867a;

/*
#020300
#36311F
#59544B
#7D8CA3
*/
