.homehead {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	color: #ddc6bf;
	font-family: 'Muli', sans-serif;
	position: relative;

	a {
		color: #ddc6bf;
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 0.15em;

		display: inline-block;
		padding: 15px 20px;
		//position: relative;
		cursor: pointer;
	}
	a:after {
		background: none repeat scroll 0 0 transparent;
		bottom: 0;
		content: "";
		display: block;
		height: 2px;
		left: 50%;
		position: absolute;
		background: #ddc6bf;
		transition: width 0.3s ease 0s, left 0.3s ease 0s;
		width: 0;
	}

	a:hover:after {
		width: 100%;
		left: 0;
	}

	.wrapper {
		position: absolute;
		top: 50vh;
		left: 50vw;
		height: 90vh;
		transform: translate(-50%, -50%);

		.head {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			.logo {
				height: 15vh;
				width: auto;
				margin-top: 10vh;
			}
			h1 {
				font-weight: 700;
				font-size: 5vmin;
				text-transform: uppercase;

				color: #ddc6bf;
				margin-top: 15vh;
				line-height: 1;
				span {
					font-size: 1.2vmin;
					display: block;
					letter-spacing: 1.5vmin;
					background: #dfc8c1;
					padding: 2vmin 2vmin 2vmin 3.5vmin;
					color: #1e1b11;
				}
				@include media('<=tablet') {
					font-size: 8vmin;
					span {
						margin-top: 2vmin;
						font-size: 3vmin;
						display: block;
						letter-spacing: 2vmin;
					}
				}
			}
		}
		.mainhead {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-top: 4vh;
			padding: 5vmin;
			i {
				font-size: 2.5vmin;
			}
		}
		.scroll-animation {
			display: flex;
			justify-content: center;
			text-align: center;
			span {
				position: absolute;
				bottom: 3%;
				a {
					font-size: 14px;
					text-transform: capitalize;
					text-align: center;
				}
			}
		}
	}
}

.home-features {
	background-color: #1e1b11;
	h1,
	h2,
	p {
		color: #dfc8c1;
		&.claim {
			text-align: center;
		}
	}
	svg {
		width: 50%;
		margin: 0 25%;

		@include media('<=tablet') {
			width: 70%;
			margin: 0 15%;
		}
	}
	.prize {
		margin: 1em 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		h3 {
			color: #fff;
			padding: 0 1em;
		}
	}
}
.icon-scroll,
.icon-scroll:before {
	position: absolute;
	left: 50%;
}

.icon-scroll {
	width: 40px;
	height: 70px;
	margin-left: -20px;
	bottom: 10%;
	margin-top: -35px;
	box-shadow: inset 0 0 0 1px #fff;
	border-radius: 25px;
}
.icon-scroll:before {
	content: '';
	width: 8px;
	height: 8px;
	background: #fff;
	margin-left: -4px;
	top: 8px;
	border-radius: 4px;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: scroll;
}
@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(46px);
	}
}
