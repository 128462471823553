.distributors-features {
	background-color: #1e1b11;
	h1,
	h2,
	h3,
	p {
		color: #dfc8c1;
		&.claim {
			text-align: center;
		}
	}

	a,
	a:link {
		color: #dfc8c1;
		text-decoration: none;
	}
	svg {
		width: 60%;
		margin: 0 auto;
	}

	// .text {
	// 	display: flex;
	// 	flex-direction: row;
	// 	justify-content: space-evenly;
	// 	div {
	// 		padding: 0 1vw;
	// 	}
	// 	@include media('<=desktop') {
	// 		flex-direction: column;
	// 		div {
	// 			padding: 0;
	// 		}
	// 	}
	// }
	.title {
		width: 100%;
		display: flex;
		padding: 1rem;
	}
}
