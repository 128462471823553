// header
header {
	.header {
		//STARTING
		// background-color: '#ffffff';
		// opacity: 0.8;
		// height: 80px;

		width: 100%;
		height: 80px;
		padding: 0 1vmin;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 15;
		display: flex;
		flex-direction: row;
		align-items: center;
		/* justify-content: space-around; */
		justify-content: space-between;

		&.hidden {
			top: -60px;
		}
		.header-logo {
			width: 60px;
			height: 60px;

			// @include media('<=tablet') {
			// 	display: none;
			// }
		}
		.menu {
			//hide horizonthal menu from tablet and below
			//@include media("<=975px") {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			ul {
				list-style-type: none;
				align-items: center;
				&.main-menu {
					display: flex;
					justify-content: center;
					// margin: 0 1vmin;
					margin-left: 5vw;
					@include media("<=tablet") {
						display: none;
					}
				}
				li {
					margin: 0 1vmin;
					&.active {
						font-size: 125%;
						font-weight: 700;
					}

					a,
					a:link,
					a:visited {
						text-transform: uppercase;
						//font-weight: 700;
						text-decoration: none;
						color: inherit;
						font-size: 90%;
					}
				}
			}

			ul.language-menu {
				width: 10vw;
				li.language {
					// margin: 0 6vmin;
					font-size: 100%;
					a {
						font-weight: 700;
					}
					span {
						//opacity: .5;
						font-weight: 300;
						font-size: 80%;
					}
				}
				@include media("<=tablet") {
					display: none;
				}
			}
		}
	}
	.nav-display {
		display: none !important;
	}
	.nav-open {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: #1e1b11;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 998;
		//scroll
		overflow: auto;
		.logo-mobile {
			width: 15vw;
			height: 15vw;
			display: block;
			margin: 0 auto 5vh auto;
			@include media("<=tablet") {
				width: 25vw;
				height: 25vw;
			}
		}
		ul li {
			list-style: none;
			text-align: center;
			padding: 2vh 5vw; //30px 50px;

			a {
				color: #ddc6bf;
				font-weight: 700;
				text-decoration: none;
				font-size: 1.2rem;
			}
			span {
				color: #ddc6bf;
			}
		}
	}
	// .nav-open ul {
	// }
	// .nav-open .nav-open ul li:hover {
	// 	color: grey;
	// }
	.fixed-nav {
		font-size: 30px;
		color: #ddc6bf;
		width: 80px;
		height: 60px;
		position: fixed;
		right: 0;
		top: 0;
		justify-content: center;
		align-items: center;
		z-index: 999;
		display: none;
		@include media("<=tablet") {
			display: flex;
		}
	}
	.bar {
		position: absolute;
		font-size: 2rem;
		cursor: pointer;
		div {
			height: 3px;
			width: 25px;
			border-radius: 3px;
			margin: 5px 0px;
			background-color: #1e1b11;
			position: relative;
		}
		div:nth-child(2) {
			width: 20px;
		}
	}
}
