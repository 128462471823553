// ------------------------------
// Gallery

.Grid {
	display: grid;
	grid-gap: 5px;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	//grid-auto-rows: 200px;
	grid-auto-rows: 120px;
	grid-auto-flow: dense;
	//grid-template-columns: 1fr 1fr 1fr;
	//background: black;
	transition: opacity .3s linear;

	.horizontal {
		grid-column: span 2;
	}
	.vertical {
		grid-row: span 2;
	}
	.big {
		grid-column: span 2;
		grid-row: span 2;
	}

	&:hover {
		img {
			opacity: .3;
		}
		img:hover {
			opacity: 1;
			transition-duration: .3s;
			cursor: pointer;
		}
	}
}

// .Grid img {
// 	transition: opacity .3s linear;
// }
// .Grid:hover {
// 	img {
// 		opacity: .3;
// 	}
// 	img:hover {
// 		opacity: 1;
// 		transition-duration: .3s;
// 		cursor: pointer;
// 	}
// }

.Figure {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
	color: #ddd;
	margin: 0;
}

.Figure > img {
	display: block;
	width: 100%;
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	transform-origin: top left;
}

// ------------------------------
// ZoomedIn View
.ZoomedIn {
	z-index: 16;
	margin: 0;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background: rgba(0, 0, 0, .9);
	display: flex;
	transition: opacity .5s linear;

	> .Figure {
		max-width: 80vw;
		max-height: 80vh;
		margin: auto;
	}

	//CLOSE BTN
	div.close {
		color: #fff;
		font-size: 2rem;
		position: absolute;
		top: 3vmin;
		right: 3vmin;
		transition: opacity .2s linear;
		cursor: pointer;
	}
	@include media('<=desktop') {
		div {
			font-size: 1rem;
			top: 100px;
		}
	}
}
[data-state="gallery"] .ZoomedIn {
	opacity: 0;
	pointer-events: none;
}

[data-state="detail"] .ZoomedIn {
	opacity: 1;
	pointer-events: auto;

	img {
		will-change: transform;
	}
}
