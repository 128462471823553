.contact-us-features {
	background-color: #1e1b11;
	h1,
	h2,
	p,
	small {
		color: #dfc8c1;
		&.claim {
			text-align: center;
		}
	}

	a,
	a:link {
		color: #dfc8c1;
		text-decoration: none;
	}
	svg {
		width: 60%;
		margin: 0 auto;
	}
	.title {
		width: 100%;
		display: flex;
		margin: 0 4vw;
	}
	form {
		label {
			color: #dfc8c1;
		}
		input[type=text],
		input[type=email],
		select,
		textarea {
			width: 100%;
			padding: 12px;
			background-color: #dfc8c1;
			border: 1px solid #eee;

			box-sizing: border-box;
			margin-top: 6px;
			margin-bottom: 16px;
			resize: vertical;
		}
		button[type=submit] {
			background-color: #dfc8c1;
			color: #1e1b11;
			padding: 1rem 2rem;
			margin: 4vmin 0;
			font-size: 1rem;
			border: none;
			cursor: pointer;
		}
		button[type=submit]:hover {
			background-color: #dfc8c1;
		}
	}
}
