footer {
	text-align: center;
	height: 300px;
	padding: 5vh 0;
	background: $brown;
	border-top: 2px solid $black;

	.footer-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding: 5vh 0;
		.footer-side {
			//flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-self: flex-start;
		}
	}
	a,
	a:link,
	a:visited,
	a:active {
		color: $white;
		text-decoration: none;
	}
	h3 {
		color: #eee;
	}
	p.company-data {
		padding-top: 20px;
		font-weight: 300;
		font-size: 0.8em;
		color: $lightsand;
	}
	p.social {
		margin-top: 150px;
		font-size: 1.5em;
		font-weight: 500;
		opacity: 0.8;
		a {
			margin: 8px;
			color: $lightsand;
		}
	}

	.bottom {
		font-size: 0.8em;
		font-weight: 500;
		opacity: 0.4;
		color: #eee;
		background-color: $black;
		padding: 3vh;
	}
	.logo {
		max-width: 40vw;
		@include media("<=tablet") {
			max-width: 90vw;
		}
	}
	.social-icons {
		padding: 2vmin;
		svg {
			width: 30px;
			height: 30px;
			margin: 0 2vmin;
		}
	}
}
