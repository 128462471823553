:root {
	--headerOpacity: 1;
	--headerScale: 1;
}

@mixin coverer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}
:root {
	--headerOpacity: 1;
	--headerScale: 1;
}

@mixin coverer {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.bnb-features {
	// padding-top: 10vh;
	background-color: #1e1b11;
	h1,
	h2,
	p {
		color: #dfc8c1;
		&.claim {
			text-align: center;
		}
	}
	a,
	a:link,
	a:visited {
		color: #fff;
		text-decoration: none;
		margin-left: 1vw;
	}

	svg {
		width: 60%;
		margin: 0 auto;
	}
	.video-header {
		position: absolute;
		text-align: center;
		width: 100vw;
		height: 100vh;
		z-index: -1;

		&,
		video,
		.viewport-header {
			@include coverer;
		}
		video {
			background: #1e1b11;
			object-fit: cover;
			@include media('<=tablet') {
				object-fit: none;
				height: 50vh;
			}
		}
		.viewport-header {
			display: flex;
			align-items: center;
			justify-content: center;

			opacity: var(--headerOpacity);
			transform: scale(var(--headerScale));

			min-height: 100vh;
			// mix-blend-mode: luminosity;
			// color: #dfc8c1;
			color: #fff;
			h1 {
				text-transform: uppercase;
				font-size: 15vmin;
				text-align: center;
				letter-spacing: 2vmin;

				span {
					display: block;
					letter-spacing: 3vmin;
					font-size: 2vmin;
				}
			}
			@include media('<=tablet') {
				height: 50vh;
				min-height: 50vh;
			}
		}
	}
	.bnb-content {
		background: #1e1b11;
		position: relative;
		// padding: 1rem;
		margin-top: 100vh;
		@include media('<=tablet') {
			margin-top: 50vh;
		}
		&::before {
			content: "";
			@include coverer;
			top: -100vh;
			@include media('<=tablet') {
				top: -50vh;
			}
		}
	}
}

/*
//FIXED HEADER FROM:
//https://css-tricks.com/full-page-background-video-styles/
//https://codepen.io/chriscoyier/pen/pPqrmr
*/
