//@import url('https://fonts.googleapis.com/css?family=Roboto:300,700,900&display=swap');
//@import url('https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre:700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700&display=swap');
//@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap");
@import "./variables.scss";
@import "./breakpoints.scss";

@import "./components/Header.scss";
@import "./components/Footer.scss";
@import "./components/Gallery.scss";

@import "./pages/Home.scss";
@import "./pages/Production.scss";
@import "./pages/Wines.scss";
@import "./pages/Distributors.scss";
@import "./pages/BnB.scss";
@import "./pages/ContactUs.scss";

svg {
	//logo
	.cls-1 {
		fill: #4c3d2e;
	}
	//logo-clear
	.cls-2 {
		fill: #dfc8c1;
	}
	//consorzio-tutela-colline-teramane
	.cls-ctct-1 {
		fill: #c6c7c9;
	}
	.cls-ctct-2 {
		//fill: #221f20;
		fill: #c6c7c9;
	}
	.cls-ctct-3 {
		fill: #626365;
	}
	.cls-footer-1 {
		opacity: 0.7;
	}
	.cls-footer-2 {
		fill: #4c3d2e;
	}
	//instagram
	/* .a {
		stop-color: #ffc107;
	}
	.b {
		stop-color: #f44336;
	}
	.c {
		stop-color: #9c27b0;
	} */
	&#orsoeape {
		.cls-1 {
			fill: #f7e500;
			stroke: #0c0601;
		}
		.cls-1,
		.cls-2,
		.cls-4 {
			stroke-miterlimit: 10;
		}
		.cls-2 {
			fill: none;
			stroke: #000;
		}
		.cls-3 {
			fill: #bc5f00;
		}
		.cls-4 {
			//stroke: #020100;
			stroke: #fff;
			fill: #fff;
		}
	}
}
.icon {
	svg {
		width: 25px;
		height: 25px;
		margin: 0;
		padding: 0;
	}
}
.big-icon {
	svg {
		width: 120px;
		height: 120px;
		margin: 0;
		padding: 0;
	}
}
* {
	margin: 0;
	padding: 0;
}
html {
	scroll-behavior: smooth;
}
html,
body {
	width: 100%;
	height: 100%;

	user-select: none;
	background: $white;
	overscroll-behavior: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	margin: 0;
	padding: 0;
	font-family: 'Muli', sans-serif;
}

p {
	font-size: 1.2rem;
	letter-spacing: .12rem;
	font-weight: 300;
}
h2 {
	margin: 2vh auto;
	font-size: 3vmin;
	font-weight: 700;
	@include media('<=tablet') {
		font-size: 5vmin;
	}
}
main {
	width: 100%;
	margin: 0;
	padding: 0;
}
.text-center {
	text-align: center;
}
.text-justify {
	text-align: justify;
}
.blk-bkg {
	background-color: $black;
}
.loading-screen {
	position: absolute;
	width: 100%;
	height: 100vh;
	background: $brown;
	z-index: 999;
}

.container {
	width: 1560px;
	min-width: 1560px;
	margin: 20vh auto;
	padding: 0 1em;
	height: 100%;
	@include media("<=1560px") {
		width: 1280px;
		min-width: 1280px;
		margin: 20vh auto;
	}
	@include media("<=desktop") {
		width: 1080px;
		min-width: 1080px;
		margin: 10vh auto;
	}
	@include media("<=1080px") {
		width: 100%;
		min-width: 100%;
		margin: 10vh auto;
	}
	.row {
		height: 100%;
		padding: 0 32px;
		@include media("<=phone") {
			padding: 0 16px;
		}
	}
	.v-center {
		align-items: center;
	}
	.space-between {
		justify-content: space-between;
	}
}

.container-fluid {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	@include media('<=tablet') {
		flex-direction: column;
	}
	&.reverse {
		flex-direction: row-reverse;
		@include media('<=tablet') {
			flex-direction: column-reverse;
		}
	}

	// .row {
	// 	display: flex;
	// 	height: 100%;
	// }

	//https://w3bits.com/css-image-hover-zoom/
	.image {
		max-width: 50%;
		overflow: hidden;
		&:hover {
			img {
				filter: brightness(110%);
				transform: scale(1.2);
			}
		}
		img {
			max-width: 100%;
			transform-origin: 50% 65%;
			transition: transform 5s, filter 3s ease-in-out;
			filter: brightness(100%);
		}
		@include media('<=tablet') {
			max-width: 100%;
		}
	}
	.image-small {
		max-width: 40%;
		display: flex;
		overflow: hidden;
		&:hover {
			img {
				filter: brightness(110%);
				transform: scale(1.2);
			}
		}
		img {
			max-width: 90%;
			// align-self: flex-end;
			// padding: .5rem;
			// border: 1px solid #4c3d2e;
			transform-origin: 50% 65%;
			transition: transform 5s, filter 3s ease-in-out;
			filter: brightness(100%);
			@include media('<=desktop') {
				max-width: 100%;
			}
		}
		@include media('<=tablet') {
			max-width: 100%;
		}
	}
	.text {
		flex: 2 0 0%;
		/* and eventually */
		//padding: 10vw;
		padding: 4vw;
		margin: auto 0;
	}
	h1 {
		font-weight: 700;
		//font-size: 10vmin;
		font-size: 8vmin;
		// text-transform: capitalize;
		letter-spacing: 1.6vmin;
		margin: 3vmin 0 3.5vmin;
		line-height: 1;
		@include media('<=desktop') {
			font-size: 5vmin;
			letter-spacing: 1.2vmin;
			margin: 3vmin 0 0;
		}
		@include media('<=tablet') {
			font-size: 12vmin;
			letter-spacing: 2vmin;
			margin: 3vmin 2vmin 2vmin 0;
		}
	}
}

.container-grid {
	width: 100%;
	height: 100%;
	padding: 1rem;

	//https://blog.fullstackdigital.com/how-to-create-a-flawless-responsive-post-grid-with-flexbox-e5c7cc9d28e
	.grid-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;

		.grid-item {
			height: 350px;
			flex-basis: 25%;
			//-ms-flex: auto;

			width: 259px;
			position: relative;
			box-sizing: border-box;
			@include media('<=desktop') {
				flex-basis: 50%;
			}

			@include media('<=tablet') {
				flex-basis: 100%;
			}
		}
	}
}

//PARALLAX
//https://gist.github.com/zakirsajib/c43efa1eccc801a2d09c9cbe498da7e7
.parallax-section {
	position: relative;
	width: 100%;
	// height: 700px;
	height: 50vmin;
	background-color: $brown;
}
.parallax-child-section {
	clip: rect(0, auto, auto, 0);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// height: 700px;
	height: 50vmin;
}

.frontlash {
	position: fixed;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translateZ(0);
	will-change: transform;
	z-index: 1;
}
.bg-cover {
	background-size: cover;
}
.fw-main-row {
	background-attachment: scroll;
	//background-image: url(###.jpg); //set in react
	//background-position: center center;
	background-position: center 25%;
	background-repeat: no-repeat;
	@include media("<=tablet") {
		background-position: center center;
	}
}

//animation: cameraPan 30s infinite;
@keyframes cameraPan {
	0% {
		background-position: 0% 0%;
	}
	25% {
		background-position: 40% 10%;
	}
	50% {
		background-position: 0% 10%;
	}
	75% {
		background-position: 10% 40%;
	}
	100% {
		background-position: 0% 0%;
	}
}
