//WINES SECTION
.wines-features {
	padding-top: 10vh;
	background-color: #1e1b11;
	h1,
	h2,
	p {
		color: #dfc8c1;
		&.claim {
			text-align: center;
		}
	}
	a,
	a:link,
	a:visited {
		color: #fff;
	}

	svg {
		width: 60%;
		margin: 0 auto;
	}
	.prize {
		margin: 1em 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		p {
			color: #fff;
			padding: 0 1em;
		}
	}
}

.sticky {
	position: sticky;
	top: 88px;
	left: 0;
	right: 0;
	z-index: 10;
	.header {
		display: flex;
		justify-content: center;
		background-color: #1e1b11;
		//#010101;
		.header_link {
			padding: 2vmin;
			letter-spacing: .2vmin;
			color: #dfc8c1;
			font-weight: 600;
			text-transform: uppercase;
			border: none;
			border-bottom: 3px solid transparent;
			cursor: pointer;
			outline: none;
			background: transparent;
			&.selected {
				border-bottom: 3px solid #dfc8c1;
				color: #dfc8c1;
			}
		}
	}
}

.slide {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;
	height: 110vh;
	margin-top: 5vh;
	@include media('<=tablet') {
		height: auto;
		margin: 0;
	}
	.card {
		width: 100%;
		height: 100%;
		background: $black;
		//#101010;
		display: flex;
		@include media('<=tablet') {
			flex-direction: column;
			height: auto;
		}
		.card-img {
			display: block;
			background-position: center;
			width: 25%;
			height: 110vh;
			margin: 0 5vmin;

			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-repeat: no-repeat;
			@include media('<=tablet') {
				width: 100%;
				padding: 0;
				margin: 15vmin 0 0 0;
			}
		}

		.card-content {
			padding: 10% 5%;
			box-sizing: border-box;
			width: 68%;
			/* height: 100vh; */
			/*background: #101010;*/
			display: inline-block;
			/* align-self: flex-end; */
			align-self: center;
			@include media('<=tablet') {
				width: 100%;
			}
			.card-theme {
				font-weight: 900;
				//font-size: 1.7vmin;
				font-size: 2vmin;
				text-transform: uppercase;

				letter-spacing: 10px;
				color: #dfc8c1;
			}
			.card-header {
				font-weight: 700;
				font-size: 10vmin;
				text-transform: capitalize;

				letter-spacing: 2vmin;
				color: #dfc8c1;
				margin: 3vmin 0 3.5vmin;
				line-height: 1;
			}
			.card-para {
				font-weight: 300;
				font-size: 1.6vmin;
				//font-size: 0.65rem;
				font-size: 0.9rem;
				letter-spacing: .1rem;
				font-weight: 400;

				color: #fff;
				margin-bottom: 2.5vmin;
				line-height: 1.8;
				strong {
					font-weight: 700;
					margin-right: 1em;
				}
			}
		}
	}
}
